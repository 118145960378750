import { Routes, Route } from 'react-router-dom'
import React, {useEffect, useState} from 'react';
import Home from './pages/Home'

function App() {
  return (
      <div id="wrapper" className="wrapper">
          <Routes>
              <Route path="/" element={<Home/>} />
          </Routes>
      </div>
  );
}

export default App;
